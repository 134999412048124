<template>
  <v-container fluid>
    <Loading :loading="!loaded" />
    <div v-if="loaded">
      <v-row align="center">
        <v-col>
          <div class="text-h6">
            {{ $t('admin.personrole.title') }}
          </div>
        </v-col>

        <v-col>
          <v-text-field
            v-model="model.query.textSearch"
            autofocus
            :label="$t('search.textSearch')"
            hide-details
            single-line
            :loading="searching"
            clearable
            solo
            @input="search"
          />
        </v-col>

        <v-col class="text-right">
          <v-btn
            color="primary"
            to="/admin/user-create"
          >
            <v-icon left> mdi-account-plus </v-icon>
            {{ $t('admin.personrole.addUser') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="6"
          align="left"
          justify="baseline"
        >
          <span class="font-italic font-weight-thin">{{ $tc('admin.personrole.resultsFound', model.data.persons.length) }}</span>
        </v-col>
        <v-col
          align="right"
          cols="6"
        >
          <!-- <div  style="position: relative; height: 10px; z-index: 1000;">
            <DeliveryCreate @saved="search" :listButton="true" />
          </div> -->
        </v-col>
      </v-row>

      <v-divider />

      <v-data-table
        :headers="headers"
        :items="model.data.persons"
      >
        <template #[`item.actions`]="{ item }">
          <v-btn :to="'user-edit/' + item.id" icon>
            <v-icon> mdi-pencil-outline </v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <div v-if="model.data">
        <v-simple-table
          dense
          fixed-header
          height="90vh"
        >
          <template #default>
            <thead>
              <tr>
                <th>{{ $t('admin.personrole.tableheader.organization') }}</th>
                <th>{{ $t('admin.personrole.tableheader.fullname') }}</th>
                <th>{{ $t('admin.personrole.tableheader.title') }}</th>
                <th>{{ $t('admin.personrole.tableheader.office') }}</th>
                <th>{{ $t('admin.personrole.tableheader.email') }}</th>
                <th>{{ $t('admin.personrole.tableheader.phone') }}</th>
                <th>{{ $t('admin.personrole.tableheader.cellphone') }}</th>
                
                <th
                  v-for="roleCategory in model.data.roleCategories"
                  :key="roleCategory.id"
                  :colspan="roleCategory.roles.length"
                >
                  {{ roleCategory.title }}
                </th>
              </tr>
              <tr>
                <th />
                <th />
                <th colspan="5" />
                <template v-for="roleCategory in model.data.roleCategories">
                  <th
                    v-for="(role, rIndex) in roleCategory.roles"
                    :key="role.id"
                    :style="rIndex==0 ? 'border-left: 2px solid black;' : null "
                  >
                    {{ role.title }}
                  </th>
                </template>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="person in model.data.persons"
                :key="person.id"
              >
                <td class="nowrap">
                  {{ person.organizationTitle }}
                </td>
                <td class="nowrap">
                  {{ person.fullName }}
                </td>
                <td class="nowrap">
                  {{ person.title }}
                </td>
                <td class="nowrap">
                  {{ person.office }}
                </td>
                <td class="nowrap">
                  {{ person.email }}
                </td>
                <td class="nowrap">
                  {{ person.phone }}
                </td>
                <td class="nowrap">
                  {{ person.cellPhone }}
                </td>

                <template v-for="roleCategory in person.roleCategories">
                  <td
                    v-for="(role, rIndex) in roleCategory.roles"
                    :key="role.id"
                    :style="rIndex==0 ? 'border-left: 2px solid black;' : null "
                  >
                    <PersonRoleEdit
                      :id="role.membership ? role.membership.id : null"
                      :person-id="person.id"
                      :role-id="role.id"
                      @saved="search"
                    />
                  </td>
                </template>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
  </v-container>
</template>

<script>
var searchTimeout = null;
import Loading from '@/components/Loading.vue'
import PersonRoleEdit from '@/components/PersonRoleEdit.vue'

export default {
  name: 'PersonRole',
  components: { Loading, PersonRoleEdit },
  data() {
    return {
      model: Object,
      loaded: false,
      searching: false,
      headers: [
        { text: 'Organisation', value: 'organizationTitle' },
        { text: 'Navn', value: 'fullName' },
        { text: 'Titel', value: 'title' },
        { text: 'Kontor', value: 'office' },
        { text: 'E-mail', value: 'email' },
        { text: 'Telefon', value: 'phone' },
        { text: 'Mobil', value: 'cellPhone' },
        { text: '', value: 'actions', align: 'right', sortable: false }
      ]
    }
  },
  created: async function() {
    try {
      const { data } = await this.$AuthService.api.get('organization/initpersonrolelist')

      this.model = data
    } catch (error) {
      console.error(error)
      this.$notifier.showError({})
    } finally {
      this.loaded = true
    }
  },
  methods: {
    search() {
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        this.searching = true;
        this.$AuthService.api.post('organization/personrolelist', this.model.query)
        .then(response => {
          this.model.data = response.data.data;
          this.searching = false;
        });
      }, 450);
    }
  }
}
</script>


<style scoped>
td.nowrap {
    white-space: nowrap;
}

table thead tr th:nth-child(2) {
  position: sticky;
  left: 0;
  z-index: 3 !important;
  background: white;
}

table tbody td:nth-child(2) {
  position: sticky;
  left: 0;
  z-index: 2 !important;
  background: white;
}


</style>