<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="500"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        outlined
        small
        color="secondary"
        v-bind="attrs"
        v-on="on"
        @click="dialogInit"
      >
        <v-icon :color="id ? 'black' : 'grey'">
          mdi-account-convert
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="title white--text secondary">
        <span v-if="id">Ændring </span>
        <span v-else>Oprettelse </span>
        <span>af rollemedlemsskab</span>
      </v-card-title>
      <v-card-text>
        <Loading :loading="!loaded" />
        <v-container
          v-if="loaded && model"
          fluid
        >
          <v-row>
            <v-col>
              {{ model.data.personFullNameWithDetails }}<br>
              {{ model.data.roleCategoryTitle }}<br>
              {{ model.data.roleTitle }}<br>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-datetime-picker
                v-model="model.data.membership.validFrom"
                use-iso
                label="Valid From..."
                date-format="yyyy-MM-dd"
                time-format="HH:mm:ss"
                :time-picker-props="timeProps"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock</v-icon>
                </template>
                <template
                  slot="actions"
                  slot-scope="{ parent }"
                >
                  <v-btn
                    outlined
                    color="primary"
                    @click.native="parent.cancelHandler"
                  >
                    Annuller
                  </v-btn>
                  <v-btn
                    color="secondary"
                    @click="parent.okHandler"
                  >
                    OK
                  </v-btn>
                </template>
              </v-datetime-picker>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-datetime-picker
                v-model="model.data.membership.validTo"
                use-iso
                label="Valid To..."
                date-format="yyyy-MM-dd"
                time-format="HH:mm:ss"
                :time-picker-props="timeProps"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock</v-icon>
                </template>
                <template
                  slot="actions"
                  slot-scope="{ parent }"
                >
                  <v-btn
                    outlined
                    color="primary"
                    @click.native="parent.cancelHandler"
                  >
                    Annuller
                  </v-btn>
                  <v-btn
                    color="secondary"
                    @click="parent.okHandler"
                  >
                    OK
                  </v-btn>
                </template>
              </v-datetime-picker>
            </v-col>
          </v-row>
          <v-row v-if="model.data.roleAllocationRequired">
            <v-col>
              <v-text-field
                v-model="model.data.membership.allocationNumber"
                label="AllocationNumber"
              />
            </v-col>
          </v-row>
          <v-row v-if="model.data.roleAllocationRequired">
            <v-col>
              <v-currency-field
                v-model="model.data.membership.allocatedHoursPerMonth"
                label="AllocatedHoursPerMonth"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions v-if="loaded">
        <v-spacer />
        <v-btn
          outlined
          color="primary"
          @click="dialogCancel"
        >
          Annuller
        </v-btn>
        <v-btn
          color="secondary"
          :loading="saving"
          @click="dialogSave"
        >
          Gem
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Loading from '@/components/Loading'

export default {
  name: "PersonRoleEdit",
  components: {
    Loading
  },
  props: {
    personId: {type: String},
    roleId: {type: String},
    id: {type: String}
  },
  data() {
    return {
      model: Object,
      dialog: false,
      loaded: false,
      saving: false,
      timeProps: {
        format: "24hr",
        useSeconds: true,
      }
    };
  },
  methods: {
    dialogInit: function ()  {
      var vm = this;

      vm.$AuthService.api
        .get(`organization/personroleedit/${vm.personId}/${vm.roleId}`)
        .then((response) => {
          vm.model = response.data;
          vm.dialog = true;
          vm.loaded = true;
        });
    },
    dialogSave: function () {
      var vm = this;
      vm.saving = true;

      vm.$AuthService.api.put(`organization/personroleedit/${vm.personId}/${vm.roleId}`, this.model.data)
        .then((response) => {
            vm.saving = false;
            vm.dialog = false;
            this.$emit("saved");
        });
    },
    dialogCancel: function () {
      // Reset to initial values
      this.model = null;
      this.dialog = false;
      this.loaded = false;
    },
  },
};
</script>